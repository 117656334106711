<template>
  <form
    id="t-searchform"
    class="search-form"
    name="team_search"
    role="search"
    method="get"
    action="./result-team.html">
    <label for="school_name" onclick="">
      <h2 class="search-form__title">チーム名で探す</h2>
      <vue-autosuggest
        @focus="focusMe"
        @input="onInputChange"
        :suggestions="filteredOptions"
        :input-props="inputProps">
      </vue-autosuggest>
      <span id="schoolSelectBoxError" class="tip error">チームを選択してください</span>
    </label>
    <input id="schoolSelectSubmit" type="submit" value="チームの予約状況を検索する">
  </form>

</template>

<script>
import Vue from 'vue/dist/vue.js';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  components: {
    VueAutosuggest
  },
  data: function(){
    return {
      selected: '',
      options: [{
        data: ['']
      }],
      filteredOptions: [],
      inputProps: {
        id:'team_name',
        name: 'team_name',
        required: 'required',
        placeholder:'選択、または候補にない場合は記入してください'
      }
    }
  },
  created: function () {
    this.getSheetToList('/チームリスト.json', 3, 'team');
  },
  methods: {
    getSheetToList: function (file, target, export_to) {
      var _this = this;
      window.axios.get(file)
        .then(function(response) {
          var list = [];
          response.data.values.forEach(function(item, index){
            var set_value = item[target];
            set_value = set_value.replace(/^[ 　]+/,"");
            set_value = set_value.replace(/[ 　]+$/,"");

            if(item.length === 0 || item[0]==="ID" || set_value==='' || set_value===' ' || set_value === ',') return;
            if(list.filter(
              function( value, index ) {
        		    return value === set_value;
          	}).length === 0) {
              list.push(set_value);
            }
          });
          _this.options[0].data = list;
          // console.log(export_to,'exported',list)
        })
        .catch(function(error) {
          console.log('ERROR!! happend by Backend.',error);
        });
      },
      onInputChange(text) {
        if (text === '' || text === undefined) {
          return this.filteredOptions = [{ data: this.options[0].data }];
        }
        /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
        const filteredData = this.options[0].data.filter(item => {
          return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
        }).slice(0, this.limit);

        this.filteredOptions = [{
          data: filteredData
        }];
      },
      focusMe(e) {
        this.onInputChange(e.target.value);
    }
  }
};

</script>
