var Vue = require('vue/dist/vue.js');

function getUrlParams() {
  return window.location.search.substring(1)
    .split('&')
    .reduce(function(result, query) {
      var pair = query.split('='); result[pair[0]] = decodeURI(pair[1]);
      return result;
    }, {});
}

function changeLoadingMesage(html){
  document.getElementById("loadingMessage").innerHTML = html;
}

(function(){


  if(location.pathname !== '/ground-map.html') return false;

  var app = new Vue({
    el: '#mainContent',
    data: {
      header: {
        show: false
      },
      notfound: false,
      show: false,
      ground: {
        id: '',
        name: '',
        latlng: []
      }
    }
  });
  changeLoadingMesage('マップを表示しています');

  if(!getUrlParams().ground_name){
    app.notfound = true;
    changeLoadingMesage('指定したグラウンドが<br>見つかりませんでした');
    return;// window.alert('指定したグラウンドが見つかりませんでした');
  }else{
    app.header.show = true;
  }
  var ground_id = getUrlParams().ground_name.substr(0, 3);

  app.ground.id = ground_id;
  //console.log(getUrlParams().ground_name);

  window.axios.get(encodeURI('/グラウンドリスト.json'))
  .then(function(response) {
    // console.log('index', response.data.values);
    // console.log(response.data.values[0]);
    response.data.values.forEach(function(item, index, array) {
      if(app.ground.id === item[1]){
        app.ground.name = item[2];
        if(typeof item[7] !== 'undefined'){
          var coordinates = item[7].split(',');
          app.ground.latlng = coordinates[1]+','+coordinates[0];
        }
        app.show = true;
        return;
      }
    });
  })
  .catch(function(error) {
    app.notfound = true;
    changeLoadingMesage('指定したグラウンドが<br>見つかりませんでした');
    console.log('ERROR!! happend by Backend.',error);
  });

})();
