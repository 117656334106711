
function check_selected_ground(){
  if(document.ground_search.ground_name.value == ''){
    $('#groundSelectBoxError').show();
    return false;
  }else{
    $('#groundSelectBoxError').hide();
    return true;
  }
}

$(function(){

  $('#groundSelectBoxError').hide();

  $('#groundSelectSubmit').on('click',function(){
    if(check_selected_ground()){
      document.ground_search.submit();
    }
    return false;
  });

});
