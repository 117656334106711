<template>
  <vue-autosuggest
    @focus="focusMe"
    @input="onInputChange"
    :suggestions="filteredOptions"
    :input-props="inputProps">
  </vue-autosuggest>
</template>

<script>
import Vue from 'vue/dist/vue.js';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  components: {
    VueAutosuggest
  },
  model: {
    prop: 'format',
    event: 'change'
  },
  props: [
    'type',
    'day',
    'text',
    'list'
  ],
  data: function(){
    return {
      selected: '',
      options: [{
        data: this.list
      }],
      filteredOptions: [],
      inputProps: {
        id: this.type+'_day'+this.day,
        name: this.type+'_day'+this.day,
        required: 'required',
        placeholder: this.text
      }
    }
  },
  methods: {
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return this.filteredOptions = [{ data: this.list }];
      }
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.list.filter(item => {
        return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
      }).slice(0, this.limit);

      this.filteredOptions = [{
        data: filteredData
      }];
    },
    focusMe(e) {
      this.onInputChange(e.target.value);
    }
  }
};

</script>
