var Vue = require('vue/dist/vue.js');
var axiosBase = require('axios');

var school_name_reg;

function getUrlParams() {
  return window.location.search.substring(1)
    .split('&')
    .reduce(function(result, query) {
      var pair = query.split('='); result[pair[0]] = decodeURI(pair[1]);
      return result;
    }, {});
}

function changeLoadingMesage(html){
  document.getElementById("loadingMessage").innerHTML = html;
}

function matchName(text){
  console.log(text);
  text = String(text).replace(/[\s|\+]+/g,'') || '';
  return text.match(school_name_reg);
}

(function(){

  if(location.pathname !== '/result-search-team.html') return false;

  var app = new Vue({
    el: '#mainContent',
    data: {
      show: false,
      notfound: false,
      school: {
        name: ''
      },
      ground_ids: [],
      calendars: [],
      teams: [],
      count: 0
    }
  });

  if(!getUrlParams().school_name){
    app.notfound = true;
    changeLoadingMesage('学校が指定されていません');
    return;// window.alert('指定したグラウンドが見つかりませんでした');
  }

  var school_name = decodeURI(getUrlParams().school_name).replace(/\+/g,' ');
  app.school.name = school_name;
  school_name_reg = new RegExp(school_name.replace(/[\s|\+]+/g,''),'i');
  form_text = '登録は<a target="_blank" href="/add_schedule.html">スケジュール登録</a>をご利用ください。<br><br><a class="button" target="_blank" href="/add_schedule.html">スケジュール登録</a>';

  if(school_name === 'その他'){
    app.notfound = true;
    changeLoadingMesage(form_text);
    return;
  }else{
    getGroundList();
  }

  function getGroundList(){
    window.axios.get('/グラウンドリスト.json')
      .then(function(response) {
        console.log('グラウンドリストを読み込みました');
        response.data.values.forEach(function(item, index){
          if(item.length === 0 || item[0]==="ID" || item[3]==='' || item[3] === ',') return;
          app.ground_ids.push(item[1]);
        });
        changeLoadingMesage('予約データを読み込んでいます<br>0%')
        getGroundData(getTeamList);
      })
      .catch(function(error) {
        console.log('ERROR!! happend by Backend.',error);
      });
  }

  function getGroundData(callback, data){
    var data = data || [];
    //Not foundでもthenが通るように
    var option = {
      validateStatus: function (status) { return true; }
    }
    axiosBase.all([
        window.axios.get('/'+app.ground_ids.shift()+'.json',option),
        window.axios.get('/'+app.ground_ids.shift()+'.json',option),
        window.axios.get('/'+app.ground_ids.shift()+'.json',option),
        window.axios.get('/'+app.ground_ids.shift()+'.json',option),
        window.axios.get('/'+app.ground_ids.shift()+'.json',option)
      ])
      .then(function(response){
        response.forEach(function(item, index){
          //console.log(item.status);
          if(item.status === 200) data.push(item.data.values);
        });
        if(app.ground_ids.length > 0){
          changeLoadingMesage('予約データを読み込んでいます<br>'+(100 - app.ground_ids.length)+'%')
          return getGroundData(callback, data);
        }else{
          changeLoadingMesage('予約データを読み込んでいます<br>100%')
          return callback(data);
        }
      })
      .catch(function(error){
        console.log(error);
      });
  }

  function getTeamList(data){
    app.calendars = data;
    changeLoadingMesage('チームリストを読み込んでいます')
    window.axios.get('/チームリスト.json')
      .then(function(response) {
        response.data.values.forEach(function(item, index) {
          // console.log(index,item)
          if(item.length === 0 || index === 0 || item[0] === "ID" || item[0] === "") return;
          if(app.school.name == item[1]){
            app.teams.push(makeCalendar(item, app.calendars));
            console.log('make push',app.teams);
          }else{
            return;
          }
          //console.log(app.school.name, item);
        });
        if(school_name === 'SPLYZA Teams'){
          app.school.name = 'SPLYZA Teams';
          app.teams.push(makeCalendar([0,app.school.name,'',app.school.name], app.calendars));
          app.show = true;
        }else if(app.teams.length === 0){
          app.notfound = true;
          changeLoadingMesage('指定した学校はチームが登録されていません。<br>'+form_text);
        }else{
          app.show = true;
        }
      })
      .catch(function(error) {
        app.notfound = true;
        changeLoadingMesage('指定した学校はチームが登録されていません<br>'+form_text);
        console.log('ERROR!! happend by Backend.',error);
      });
  }

  function makeCalendar(item, data){
    var team = {
      id: item[0],
      school: item[1],
      name: item[2],
      concat_name: item[3],
      count: 0,
      calendars: []
    }
    var matchName = function(text){
      if(!text) return false;
      return text.match(team.concat_name);
    }
    console.log(team, 'makeCalendar');
    //console.log(data);
    //dataファイルを結合
    var all_data = [];
    data.forEach(function(ground_data, index){
      var ground = {};
      ground_data.forEach(function(item, index){
        if(item.length === 0 || item[0] === '' || item[0]==='日付'){
          return;
        }else if(index === 0){
          ground.id = item[0];
          ground.name = item[1];
          return;
        }else if( matchName(item[8]) || matchName(item[10]) ){
          if(new Date().getFullYear() != new Date(item[0]).getFullYear()) return;
          var data = {
            date: item[0],
            hotel: (matchName(item[8]) ? item[9] : item[11] ),
            game: [{
              start: (item[1] !== '' ? item[1]+":"+item[3] : '未定' ),
              end: (item[5] !== '' ? item[5]+":"+item[7] : '未定' ),
              vs: (matchName(item[8]) ? item[10] : item[8] ),
              ground: ground
            }]
          }
          var is_same_date = false;
          team.calendars.forEach(function(search_all_data, index){
            if(search_all_data.date === item[0]){
              search_all_data.game.push(data.game[0]);
              is_same_date = true; //console.log('同じ日付');
              team.count ++;
              return;
            }
          });
          if(!is_same_date){
            team.calendars.push(data);
            team.count ++;
          }
        }
      });
    });
    if(team.count === 0){
      team.notfound = true;
      team.message = 'このチームに予定はありません';
    }else{
      team.message = '読み込み完了';
      team.show = true;
      team.calendars.sort(function(a,b){
        if(a.date < b.date) return -1;
        if(a.date > b.date) return 1;
        return 0;
      });
      team.calendars.forEach(function(calendar, index){
        calendar.game.sort(function(a,b){
          var L = a.start.replace(/^([0-9]:)/,'0$1');
          var R = b.start.replace(/^([0-9]:)/,'0$1');
          if(L < R) return -1;
          if(L > R) return 1;
          return 0;
        });
      });
      console.log('all_data: ',team.calendars);
    }
    return team;
  }

})();
