import Vue from 'vue/dist/vue.js';
import formSuggestInput from './vue/form-suggest-input.vue';

(function(){

  if(location.pathname !== '/add_schedule.html') return false;

  var app = new Vue({
    components: {
      formSuggestInputTeam: formSuggestInput,
      formSuggestInputGround: formSuggestInput,
      formSuggestInputVs: formSuggestInput
    },
    el: '#mainContent',
    data: function() {
      return {
        list: {
          team: [],
          ground: []
        },
        schedules: [
          {
            number: 1,
            times: [{
              type: 'start',
              after: '〜'
            }]
          },
        ]
      }
    },
    methods: {
      onSubmit: function(e){
        var token = grecaptcha.getResponse(recaptcha_widget);
        if(token.length === 0){
          return alert('ロボットでないことを証明するチェックが確認できません。');
        }
        document.entryform.action = 'https://script.google.com/macros/s/AKfycbzn7DWFueD2DXtgmyFuiO2t-kaw_DwdG9zVuJ1ofzEEeFKl8Fpb/exec';
        document.entryform.submit();
      },
      addSchedule: function(e){
        var num = this.schedules.length;
        var data = JSON.parse(JSON.stringify( this.schedules[num - 1] ));
        data.number = num + 1;
        data.times = [{ type: 'start', after: '〜' }];
        this.schedules.push(data);
      },
      addEndTime: function(e){
        e.times.push({
          type: 'end',
        });
        console.log('addEndTime', e);
      }
    }
  });

  getSheetToList('/チームリスト.json', 3, 'team');
  getSheetToList('/グラウンドリスト.json', [1,2], 'ground');

  function getSheetToList(file, target, export_to){
    window.axios.get(file)
      .then(function(response) {
        var list = [];
        response.data.values.forEach(function(item, index){
          if(target.length == 2 ){
            if(item.length === 0 || item[0]==="ID" || item[target[0]]==='' || item[target[0]]===' ' || item[target[0]] === ',') return;
            list.push(item[target[0]]+'：'+item[target[1]]);
          }else{
            var set_value = item[target];
            set_value = set_value.replace(/^[ 　]+/,"");
            set_value = set_value.replace(/[ 　]+$/,"");
            if(item.length === 0 || item[0]==="ID" || set_value==='' || set_value===' ' || set_value === ',') return;
            if(list.filter(
              function( value, index ) {
        		    return value === set_value;
          	}).length === 0) {
              list.push(set_value);
            }
          }
        });
        app.list[export_to] = list
      })
      .catch(function(error) {
        console.log('ERROR!! happend by Backend.',error);
      });
  }

  var recaptcha_widget;

  window.onloadCallback = function(){
    if(recaptcha_widget === 0) return;
    recaptcha_widget = grecaptcha.render(document.getElementById('gRecaptchaElement'), {
      'sitekey' : '6LcoIq0UAAAAAGLeQicywqPe-oiStMmRLpaotBS2'
    });
  }

})();
