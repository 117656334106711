<template>
  <form id="t-searchform" class="search-form" name="ground_search" role="search" method="get" action="result-ground.html">
    <label for="ground-name" onclick="">
      <h2 class="search-form__title">グラウンド名から検索</h2>
      <vue-autosuggest
        @focus="focusMe"
        @input="onInputChange"
        :suggestions="filteredOptions"
        :input-props="inputProps">
      </vue-autosuggest>
      <span id="groundSelectBoxError" class="tip error">グラウンドを選択してください</span>
    </label>
    <input id="groundSelectSubmit" type="submit" value="グラウンドの予約状況を検索する">
  </form>
</template>

<script>
import Vue from 'vue/dist/vue.js';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  components: {
    VueAutosuggest
  },
  data: function(){
    return {
      selected: '',
      options: [{
        data: ['']
      }],
      filteredOptions: [],
      inputProps: {
        id:'ground_name',
        name: 'ground_name',
        required: 'required',
        placeholder:'選択、または候補にない場合は記入してください'
      }
    }
  },
  created: function () {
    this.getSheetToList('/グラウンドリスト.json', [1,2], 'ground');
  },
  methods: {
    getSheetToList: function (file, target, export_to) {
      var _this = this;
      window.axios.get(file)
        .then(function(response) {
          var list = [];
          response.data.values.forEach(function(item, index){
            if(item.length === 0 || item[0]==="ID" || item[target[0]]==='' || item[target[0]]===' ' || item[target[0]] === ',') return;
            list.push(item[target[0]]+'：'+item[target[1]]);
          });
          _this.options[0].data = list;
          // console.log(export_to,'exported',list)
        })
        .catch(function(error) {
          console.log('ERROR!! happend by Backend.',error);
        });
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return this.filteredOptions = [{ data: this.options[0].data }];
      }
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.options[0].data.filter(item => {
        return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
      }).slice(0, this.limit);

      this.filteredOptions = [{
        data: filteredData
      }];
    },
    focusMe(e) {
      this.onInputChange(e.target.value);
    }
  }
};

</script>
