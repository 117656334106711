// window.jQuery = window.$ = require('jquery');

// axios を require してインスタンスを生成する
var axiosBase = require('axios');
var data_path = location.hostname === 'sugadaira.splyza.com' ? 'data' : 'sample_data';

window.axios = axiosBase.create({
  baseURL: location.origin+'/'+data_path,
  // baseURL: 'http://sugadaira.lc:8080/sample_data',
  // baseURL: 'https://dwda10qlukg4.cloudfront.net/data',
  headers: {
    'ContentType': 'application/xml',
    'X-Requested-With': 'XMLHttpRequest'
  },
  withCredentials: true,
  responseType: 'json'
});

require('./search.js');
require('./search-ground.js');
require('./search-team.js');
require('./result-search-team.js');
require('./result-ground.js');
require('./result-team.js');
require('./ground-map.js');
require('./add_schedule.js');

$(function(){

  $('body').removeClass('unload');

  $(window).on('resize',function(e){
    if( window.innerWidth >= 1068 ) return this;
    if(location.pathname === '/add_schedule.html') return this;

    var DeviceHeight = window.parent.screen.height;
    var WindowHeight = window.innerHeight;
    var HeaderHeight = $('#siteHeader').height();
    var ReHeaderHeight = $('#ResultHeader').height();
    var SbannerHeight = $('#bannerS').height();
    var MapheaderHeight = $('#MapHeader').height();
    var MainHeight = (WindowHeight - HeaderHeight  - SbannerHeight ); /* メインのHeightの計算をする */

    $('body').css('height', WindowHeight ); /* ページ全体のサイズはデバイスのheightを取得したものを使用する */
    $('#map').css('height', WindowHeight ); /* Mapページは例外として、フッターサイズを考慮しない */
    $('#result').css('height', WindowHeight ); /* リザルトではbody自体はスクロールしない */
    $('#gResult').css('height', MainHeight - ReHeaderHeight - 16);
    $('#scrollContainer, #mainContent, #MapContent').css('height', MainHeight ); /* メインのHeightの算出結果を反映する */
    $('#TimeTable').css('height', MainHeight - ReHeaderHeight - 77 );
    $('#Googlemap').css('height', MainHeight - MapheaderHeight ); /* メインのHeightの算出結果を反映する */
  }).trigger('resize');

  $('.g_date').each(function(){
    this.label = '.g-search-card__daylink[for='+$(this)[0].id+']';
    this.def_val = $(this).val();
  }).on('change',function(e){
    $(this.label).html('決定');
  }).on('reset',function(e){
    $(this).val(this.def_val);
    $(this.label).html('日付変更');
  });
  $('.g-search-card__daylink').on('click',function(){
    if($(this).html() !== '決定') return this;
    var parent = '#scrollContainer';
    var target_input = '#'+$(this).attr('for');
    var target = '#day_'+$(target_input).val();
    $(target_input).trigger('focusout');
    console.log(target);
    if($(target).length){
      var scroll = $(parent).children().index($(target)) * $(target).width();
      console.log('scrollLeft',scroll);
      $(parent).trigger('scrollTo',[scroll]);
    }else{
      var date = $(target_input).val().replace(/(\d{4})-(\d{2})-(\d{2})/,'$1年$2月$3日');
      alert(date+'の予定はありません');
    }
    $(target_input).trigger('reset');
  });

  $('#scrollBtnPrev, #scrollBtnNext').on('checkActive',function(e,scroll,max_width){
    if(this.isPrev && scroll > 0) $(this).addClass('active');
    else if(this.isPrev) $(this).removeClass('active');
    if(!this.isPrev && scroll < max_width) $(this).addClass('active');
    else if(!this.isPrev) $(this).removeClass('active');
  }).on('click',function(e,scroll){
    var target = this.target;
    var offset = $(target).width() * (this.isPrev ? -1 : 1 );
    scroll = scroll || $(target).scrollLeft() + offset;
    $(target).trigger('scrollTo',[scroll]);
    return false;
  }).each(function(){
    this.target = '#scrollContainer';
    this.isPrev = $(this).hasClass('yesterday');
  });

  $('#scrollContainer').on('scroll',function(){
    $('.g_date').trigger('reset');
    $('#scrollBtnPrev, #scrollBtnNext')
      .trigger('checkActive',
        [$(this).scrollLeft(),this.scroll_max]
      );
  }).on('scrollTo',function(e,scroll){
    $(this).animate({scrollLeft: scroll}, 500);
  }).each(function(){
    this.scroll_max = this.scrollWidth - $(this).width();
    $(this).trigger('scroll');
  });

  var backPage = document.getElementById('backPage');
  var mapPage = document.getElementById('mapPage');
  var resultBackPage = document.getElementById('resultBackPage');
  if(backPage) backPage.addEventListener('click', backHistoryHandler);
  if(mapPage) backPage.addEventListener('click', backHistoryHandler);
  if(resultBackPage) resultBackPage.addEventListener('click', backHistoryHandler);
  function backHistoryHandler(callback = false){
    if(document.referrer === ""
      || document.referrer === location.href
      || window.history.length === 1
    ) return location.href = '/';
    window.history.back(-1);
    return callback;
  }

});
