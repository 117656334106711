
function check_selected_team(){
  if(document.team_search.school_name.value == ''){
    $('#schoolSelectBoxError').show();
    return false;
  }else{
    $('#schoolSelectBoxError').hide();
    return true;
  }
}

$(function(){

  $('#schoolSelectBoxError').hide();

  $('#schoolSelectSubmit').on('click',function(){
    if(check_selected_team()){
      document.team_search.submit();
    }
    return false;
  });

});
