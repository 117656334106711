var Vue = require('vue/dist/vue.js');
var axiosBase = require('axios');

var team_name_reg;

function getUrlParams() {
  return window.location.search.substring(1)
    .split('&')
    .reduce(function(result, query) {
      var pair = query.split('='); result[pair[0]] = decodeURI(pair[1]);
      return result;
    }, {});
}

function changeLoadingMesage(html){
  document.getElementById("loadingMessage").innerHTML = html;
}

function matchName(text){
  console.log(text);
  text = String(text).replace(/[\s|\+]+/g,'') || '';
  return text.match(team_name_reg);
}

(function(){

  if(location.pathname !== '/result-team.html') return false;

  var form_text = '登録は<a target="_blank" href="/add_schedule.html">スケジュール登録</a>をご利用ください。<br><br><a class="button" target="_blank" href="/add_schedule.html">スケジュール登録</a>';

  var app = new Vue({
    el: '#mainContent',
    data: {
      show: false,
      notfound: false,
      team: {
        id: '',
        name: ''
      },
      ground_ids: ["000"],
      calendars: [],
      count: 0
    }
  });

  if(!getUrlParams().team_name){
    app.notfound = true;
    changeLoadingMesage('チームが指定されていません<br>'+form_text);
    return;// window.alert('指定したグラウンドが見つかりませんでした');
  }

  var team_name = decodeURI(getUrlParams().team_name).replace(/\+/g,' ');
  app.team.name = team_name;
  team_name_reg = new RegExp(team_name.replace(/[\s|\+]+/g,''),'i');

  console.log(team_name);

  getGroundList();

  function getGroundList(){
    window.axios.get('/グラウンドリスト.json')
      .then(function(response) {
        console.log('グラウンドリストを読み込みました');
        response.data.values.forEach(function(item, index){
          if(item.length === 0 || item[0]==="ID" || item[3]==='' || item[3] === ',') return;
          app.ground_ids.push(item[1]);
        });
        changeLoadingMesage('予約データを読み込んでいます<br>0%')
        getGroundData(makeCalendar);
      })
      .catch(function(error) {
        console.log('ERROR!! happend by Backend.',error);
      });
  }

  function getGroundData(callback, data){
    var data = data || [];
    //Not foundでもthenが通るように
    var option = {
      validateStatus: function (status) { return true; }
    }
    axiosBase.all([
        window.axios.get('/'+app.ground_ids.shift()+'.json',option),
        window.axios.get('/'+app.ground_ids.shift()+'.json',option),
        window.axios.get('/'+app.ground_ids.shift()+'.json',option),
        window.axios.get('/'+app.ground_ids.shift()+'.json',option),
        window.axios.get('/'+app.ground_ids.shift()+'.json',option)
      ])
      .then(function(response){
        response.forEach(function(item, index){
          //console.log(item.status);
          if(item.status === 200) data.push(item.data.values);
        });
        if(app.ground_ids.length > 0){
          changeLoadingMesage('予約データを読み込んでいます<br>'+(100 - app.ground_ids.length)+'%')
          return getGroundData(callback, data);
        }else{
          changeLoadingMesage('予約データを読み込んでいます<br>100%')
          return callback(data);
        }
      })
      .catch(function(error){
        console.log(error);
      });
  }

  function makeCalendar(data){
    console.log(team_name, 'makeCalendar');
    console.log(data);
    //dataファイルを結合
    var all_data = [];
    data.forEach(function(ground_data, index){
      var ground = {};
      ground_data.forEach(function(item, index){
        if(item.length === 0 || item[0]==='' || item[0]==='日付'){
          return;
        }else if(index === 0){
          ground.id = item[0];
          ground.name = item[1];
          return;
        }else if( matchName(item[8]) || matchName(item[10]) ){
          console.log(item);
          if(new Date().getFullYear() != new Date(item[0]).getFullYear()) return;
          app.team.name = matchName(item[8]) ? item[8] : item[10];
          var data = {
            date: item[0],
            hotel: (matchName(item[8]) ? item[9] : item[11] ),
            game: [{
              start: (item[1] !== '' ? item[1]+":"+item[3] : '未定' ),
              end: (item[5] !== '' ? item[5]+":"+item[7] : '未定' ),
              vs: (matchName(item[8]) ? item[10] : item[8] ),
              ground: ground
            }]
          }
          var is_same_date = false;
          app.calendars.forEach(function(search_all_data, index){
            if(search_all_data.date === item[0]){
              search_all_data.game.push(data.game[0]);
              is_same_date = true; //console.log('同じ日付');
              app.count ++;
              return;
            }
          });
          if(!is_same_date){
            app.calendars.push(data);
            app.count ++;
          }
        }
      });
    });
    if(app.count === 0){
      app.notfound = true;
      changeLoadingMesage('このチームに予定はありません<br>'+form_text);
    }else{
      changeLoadingMesage('読み込み完了');
      app.show = true;
      app.calendars.sort(function(a,b){
        if(a.date < b.date) return -1;
        if(a.date > b.date) return 1;
        return 0;
      });
      app.calendars.forEach(function(calendar, index){
        calendar.game.sort(function(a,b){
          var L = a.start.replace(/^([0-9]:)/,'0$1');
          var R = b.start.replace(/^([0-9]:)/,'0$1');
          if(L < R) return -1;
          if(L > R) return 1;
          return 0;
        });
      });
      console.log('all_data: ',app.calendars);
    }
  }

})();
