var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-autosuggest", {
    attrs: { suggestions: _vm.filteredOptions, "input-props": _vm.inputProps },
    on: { focus: _vm.focusMe, input: _vm.onInputChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }