var Vue = require('vue/dist/vue.js');

function getUrlParams() {
  return window.location.search.substring(1)
    .split('&')
    .reduce(function(result, query) {
      var pair = query.split('='); result[pair[0]] = decodeURI(pair[1]);
      return result;
    }, {});
}

function changeLoadingMesage(html){
  document.getElementById("loadingMessage").innerHTML = html;
}

(function(){

  if(location.pathname !== '/result-ground.html') return false;

  var app = new Vue({
    el: '#mainContent',
    data: {
      header: {
        show: false
      },
      notfound: false,
      show: false,
      ground: {
        id: '',
        name: ''
      },
      schedules: []
    }
  });

  if(!getUrlParams().ground_name){
    app.notfound = true;
    changeLoadingMesage('指定したグラウンドが<br>見つかりませんでした');
    return;// window.alert('指定したグラウンドが見つかりませんでした');
  }else{
    app.header.show = true;
  }
  var read_seet_id = getUrlParams().ground_name.substr(0, 3);

  app.ground = {
    id: read_seet_id,
    name: ''
  };
  //console.log(getUrlParams().ground_name);


  window.axios.get('/'+read_seet_id+'.json')
  .then(function(response) {

    app.ground.id = response.data.values[0][0];
    app.ground.name = response.data.values[0][1];

    response.data.values.forEach(function(item, index) {
      // console.log(index,item)
      if(index === 0 || item.length === 0 || item[0] === "日付" || item[0] === "") return;
      if(new Date().getFullYear() != new Date(item[0]).getFullYear()) return;
      var schedule = {
        date: item[0],
        time: ["6:00","8:00","10:00","12:00","14:00","16:00","18:00","20:00","22:00"],
        game: [{
          embed: (item[1]%2 ? Number(item[1])-1 : item[1])+":00",
          start: item[1]+":"+item[3],
          end: item[5]+":"+item[7],
          team: [
            { name:item[8],  hotel: item[9]  },
            { name:item[10], hotel: item[11] }
          ]
        }]
      }
      var date = schedule.date.replace(/\//g,'-');
      //
      schedule.game[0].duration = new Date(
        (+new Date(date+" "+schedule.game[0].end+":00"))
        -(+new Date(date+" "+schedule.game[0].start+":00"))
      ).getTime() / 1000 / 60;
      //
      schedule.game[0].offset = new Date(
        (+new Date(date+" "+schedule.game[0].start+":00"))
        -(+new Date(date+" "+schedule.game[0].embed+":00"))
      ).getTime() / 1000 / 60;

      var is_same_date = false;
      app.schedules.forEach(function(search_schedule, index){
        if(search_schedule.date === item[0]){
          search_schedule.game.push(schedule.game[0]);
          is_same_date = true; //console.log('同じ日付');
          return;
        }
      });
      if(!is_same_date) app.schedules.push(schedule);
      //app.schedules.splice(0,0,schedule);
    });
    console.log(app.schedules);
    console.log(app.schedules.length);
    if(app.schedules.length === 0){
      app.notfound = true;
      changeLoadingMesage('予約された試合はありません');
    }else{
      app.schedules.sort(function(a,b){
        if(a.date < b.date) return -1;
        if(a.date > b.date) return 1;
        return 0;
      });
      app.show = true;
    }
  })
  .catch(function(error) {
    app.notfound = true;
    changeLoadingMesage('指定したグラウンドが<br>見つかりませんでした');
    console.log('ERROR!! happend by Backend.',error);
  });

})();
