import Vue from 'vue/dist/vue.js';
import formSearchTeam from './vue/form-search-team.vue';
import formSearchGround from './vue/form-search-ground.vue';

if(document.getElementById('formSearchTeam')){
  new Vue({
    el: "#formSearchTeam",
    components: { formSearchTeam },
    template: '<form-search-team></form-search-team>'
  })
}
if(document.getElementById('formSearchGround')){
  new Vue({
    el: "#formSearchGround",
    components: { formSearchGround },
    template: '<form-search-ground></form-search-ground>'
  })
}
